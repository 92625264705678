import OpenAI from 'openai';

// OpenAI 클라이언트 초기화
const openai = new OpenAI({
    apiKey: "sk-yHSbJnspV29xh4h9phy23JnxBHb4-EgNnHVoImP4MTT3BlbkFJiJSrqPGv_IfxV15JpDG_wI5uO4N8NgwiKPdE85KXkA",
    dangerouslyAllowBrowser: true // API 키를 환경 변수에서 가져옵니다.
});

export async function callFineTunedModel(modelName: string, prompt: string, labels: string[]): Promise<string> {
    let systemContent = '';
    let userContent = prompt;

    if (labels.includes('AI_AUTO_LABEL')) {
        systemContent = `당신은 회계 전문가입니다. 주어진 날짜, 거래처, 금액 정보를 바탕으로 적절한 적요를 제시해야 합니다.`;
        userContent = `다음 정보를 바탕으로 적절한 적요를 생성해 주세요: ${prompt}`;
    } else {
        systemContent = `당신은 회계 전문가입니다. 주어진 날짜, 거래처, 금액 정보를 바탕으로 적절한 적요를 제시해야 합니다. 적요는 다음 중 하나여야 합니다. [${labels.join(', ')}, 알 수 없음]`;
    }

    const completion = await openai.chat.completions.create({
        model: modelName,
        // model: "gpt-4o-2024-08-06",
        messages: [
            { "role": "system", "content": systemContent },
            { role: "user", content: userContent }],
    });
    return (completion.choices[0].message.content || '');
}