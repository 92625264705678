import React, { ChangeEvent, useState } from 'react';
import * as XLSX from 'xlsx';
import { ExcelRow, processExcelData } from '../handlers/excelHandler';
import styled from 'styled-components';

const Container = styled.div`

  max-width: 1000px;
  min-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Title = styled.h2`
  font-size: 18px;
  margin-bottom: 15px;
`;

const Section = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const InputContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Input = styled.input`
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 8px 15px;
  margin-left: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
`;

const Label = styled.div`
  padding: 5px 10px;
  background-color: #e0e0e0;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

const RemoveButton = styled.button`
  margin-left: 5px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #ff0000;
`;

const SuggestedLabels = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
`;

const SuggestedLabel = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: rgba(76, 175, 80, 0.3);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-items: baseline;
`;

const AddButton = styled.span`
  margin-left: 5px;
  color: #4caf50; // 초록색으로 변경
  cursor: pointer;
  font-size: 16px;
  font-weight: bold; // 굵게 설정

  &:hover {
    color: #45a049; // 호버 시 색상 변경
  }
`;

const DropZone = styled.div<{ $isDragging: boolean }>`
  border: 2px dashed ${props => props.$isDragging ? '#0066cc' : '#ccc'};
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
  background-color: ${props => props.$isDragging ? '#e6f2ff' : 'transparent'};
`;

const FileInput = styled.input`
  display: none;
`;

const FileInputLabel = styled.label`
  display: inline-block;
  padding: 10px 20px;
  background-color: #0066cc;
  color: white;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0052a3;
  }
`;

const Message = styled.p`
  font-size: 14px;
  color: #333;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-top: 20px;
`;

const ProgressBar = styled.div<{ width: number }>`
  width: ${props => props.width}%;
  height: 20px;
  background-color: #4caf50;
  border-radius: 4px;
  transition: width 0.3s ease-in-out;
`;

const ProgressText = styled.p`
  font-size: 14px;
  color: #333;
  margin-top: 10px;
`;

const UploadButton = styled(Button)`
  width: 200px;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 16px;
`;
const AIButton = styled(Button)`
  background-color: #3f51b5;
  &:hover {
    background-color: #303f9f;
  }
`;
const Uploader: React.FC = () => {
  const [labels, setLabels] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [suggestedLabels, setSuggestedLabels] = useState<string[]>([
    '식대', '사무용품', '소모품', '임대료', '관리비', '기장수수료',
    '광고비', '촬영비', '현금영수증매입 (가수금)', '운송비', '호스팅 서비스', '렌탈료'
  ]);
  // const suggestedLabels = ['식대', '사무용품', '소모품', '임대료', '관리비', '기장수수료', '광고비', '촬영비', '현금영수증매입 (가수금)', '운송비', '호스팅 서비스', '렌탈료'];

  const [file, setFile] = useState<File | null>(null);
  const [message, setMessage] = useState<string>('');
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [isUploading, setIsUploading] = useState(false);
  // const [isAIMode, setIsAIMode] = useState(false);

  const handleAIMode = () => {
    // setIsAIMode(true);
    addLabel("AI_AUTO_LABEL");
  };

  const addLabel = (label: string) => {
    if (label && !labels.includes(label)) {
      if (label === "AI_AUTO_LABEL") {
        setLabels(["AI_AUTO_LABEL"]);
        setInputValue('');
        // setSuggestedLabels([]);
      } else {
        setLabels([...labels.filter(l => l !== "AI_AUTO_LABEL"), label]);
        setInputValue('');
        setSuggestedLabels(suggestedLabels.filter(l => l !== label));
      }
    }
  };


  const removeLabel = (label: string) => {
    setLabels(labels.filter(l => l !== label));
    // 원래 suggestedLabel에 있던 것이면 다시 추가
    if (suggestedLabels.concat(labels).includes(label)) {
      if (label !== "AI_AUTO_LABEL") {
        setSuggestedLabels([...suggestedLabels, label]);
      }
    }
  };


  const handleFileChange = (selectedFile: File) => {
    const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();

    if (fileExtension === 'xlsx' || fileExtension === 'xls') {
      setFile(selectedFile);
      setMessage('파일이 선택되었습니다.');
    } else {
      setMessage('지원되지 않는 파일 형식입니다. .xlsx 또는 .xls 파일을 선택해주세요.');
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleFileChange(e.target.files[0]);
    }
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileChange(e.dataTransfer.files[0]);
    }
  };

  const handleUpload = async () => {
    setIsUploading(true); // 업로드 시작 시 버튼 비활성화
    if (file && labels.length > 0) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const data = e.target?.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet) as ExcelRow[];

        setTotalRows(jsonData.length);
        setProgress(0);

        processExcelData(jsonData, labels, (currentRow) => {
          const progressPercentage = (currentRow / jsonData.length) * 100;
          setProgress(progressPercentage);
        }).then((result) => {
          const jsonResult = JSON.parse(result);
          const newWorkbook = XLSX.utils.book_new();
          const newWorksheet = XLSX.utils.json_to_sheet(jsonResult);
          XLSX.utils.book_append_sheet(newWorkbook, newWorksheet, '결과');
          XLSX.writeFile(newWorkbook, 'processed_data.xlsx');
          setMessage('파일이 성공적으로 처리되었습니다!');
          setIsUploading(false); // 업로드 완료 후 버튼 활성화
        }).catch((error) => {
          console.error("데이터 처리 중 오류 발생:", error);
          setMessage('파일 처리 중 오류가 발생했습니다.');
          setIsUploading(false); // 오류 발생 시에도 버튼 활성화
        });
      };
      reader.readAsBinaryString(file);
    } else {
      setMessage('파일과 라벨을 모두 선택해주세요.');
      setIsUploading(false); // 파일이나 라벨이 없을 경우 버튼 활성화
    }
  };

  return (
    <Container>
      <Section>
        <Title>계정과목 등록</Title>
        <InputContainer>
          <Input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="입력"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                addLabel(inputValue);
              }
            }}
          />
          <Button onClick={() => addLabel(inputValue)}>추가</Button>
          <AIButton onClick={handleAIMode}>AI 자동</AIButton>
        </InputContainer>
        <LabelContainer>
          {labels.map((label, index) => (
            <Label key={index}>
              {label === "AI_AUTO_LABEL" ? "AI 자동 모드" : label}
              <RemoveButton onClick={() => removeLabel(label)}>×</RemoveButton>
            </Label>
          ))}
        </LabelContainer>

        <SuggestedLabels>
          {suggestedLabels.map((label, index) => (
            <SuggestedLabel key={index} onClick={() => addLabel(label)}>
              {label}
              <AddButton>+</AddButton>
            </SuggestedLabel>
          ))}
        </SuggestedLabels>

      </Section>

      <Section>
        <Title>계정별원장 엑셀파일 업로드</Title>
        <h4>세무 AI가 거래내역에 따른 적요를 작성합니다.</h4>
        <DropZone
          $isDragging={isDragging}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <FileInput
            type="file"
            accept=".xlsx,.xls"
            onChange={handleInputChange}
            id="file-upload"
          />
          <FileInputLabel htmlFor="file-upload">
            파일 업로드
          </FileInputLabel>
        </DropZone>
        {file && <Message>선택된 파일: {file.name}</Message>}
        {message && <Message>{message}</Message>}
        {progress > 0 && progress < 100 && (
          <>
            <ProgressBarContainer>
              <ProgressBar width={progress} />
            </ProgressBarContainer>
            <ProgressText>
              {`${Math.round(progress)}% 완료 (${Math.round(totalRows * progress / 100)} / ${totalRows} 행)`}
            </ProgressText>
          </>
        )}
      </Section>

      <UploadButton onClick={handleUpload} disabled={!file || labels.length === 0 || isUploading}>
        {isUploading ? '작업 수행중...' : '작업 수행'}
      </UploadButton>
    </Container>
  );
};

export default Uploader;