import React from 'react';
import styled from 'styled-components';
import Uploader from '../components/Uploader';
import 상상력집단Logo from '../images/상상력집단Logo.png';
const HomeContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
  font-family: 'Arial', sans-serif;
  position: relative; // 유지: 상대 위치 설정
`;

const Title = styled.h1`
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;


const Logo = styled.img`
  position: absolute;
  top: 2rem; // 변경: padding과 일치하도록 조정
  left: 2rem; // 변경: padding과 일치하도록 조정
  width: 170px;
  height: auto;
`;

const Home = () => {
  return (
    <HomeContainer>
      <Logo src={상상력집단Logo} alt="상상력집단 로고" />
      <Title>세무 AI</Title>
      {/* Subtitle을 이미지로 대체 */}
      <Uploader />
      {/* <ExcelUploader />
      <CheckLabel /> */}
    </HomeContainer>
  )
};

export default Home;