import { callFineTunedModel } from "./finetunedGpt";

export interface ExcelRow {
    "날짜": string; // 날짜
    "거래처": string; // 회사명
    "차   변": number; // 금액
}

const waitThreeSeconds = async () => {
    return new Promise(resolve => {
        setTimeout(() => {
            // console.log("3초가 지났습니다.");
            resolve(true);
        }, 130); // 3000ms = 3초
    });
};


export async function processExcelData(jsonData: ExcelRow[], labels: string[], progressCallback: (currentRow: number) => void): Promise<string> {
    const updatedData = [];

    for (let i = 0; i < jsonData.length; i++) {
        const row = jsonData[i];
        const resultString = `날짜: ${row["날짜"]}, 거래처: ${row["거래처"]}, 금액: ${row["차   변"]} 거래처명을 유의깊게 고려하여 적요를 작성해주세요.`;

        let result;
        let attempts = 0;

        do {
            result = await callFineTunedModel('ft:gpt-4o-mini-2024-07-18::modu-gpt-finetune-v0:A6D0SPzl', resultString, labels);
            attempts++;
        } while (!labels.includes('AI_AUTO_LABEL') && !labels.includes(result) && attempts < 3);

        if (!labels.includes('AI_AUTO_LABEL')) {
            if (!labels.includes(result)) {
                result = "알 수 없음"; // 3번 시도 후에도 결과가 labels에 없으면 "알 수 없음"으로 설정
            }
        }

        updatedData.push({
            ...row,
            "추천 적요": result // F열에 API 결과 추가
        });

        await waitThreeSeconds(); // 각 행 처리 후 3초 대기
        progressCallback(i + 1); // 현재 처리 중인 행 번호 전달
    }

    return JSON.stringify(updatedData, null, 2);
}